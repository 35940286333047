import { NextPage } from 'next';
import { MainLayout } from '../common/containers/MainLayout/MainLayout';
import { t, Trans } from '@lingui/macro';
import { Link as MuiLink, Typography } from '@mui/material';
import styled from 'styled-components';
import { useUserData } from '../common/hooks/useUserData';
import Link from 'next/link';
import { Routes } from '../common/enums/routes';

const ErrorPage: NextPage = () => {
  const { user, loading } = useUserData();

  return (
    <MainLayout protectedRoute={false} title={t`Stránka neexistuje`}>
      <Wrapper>
        <Typography variant="h3" align="center">
          <Trans>Stránka neexistuje</Trans>
        </Typography>

        {!loading && !user && (
          <Link href={Routes.Login}>
            <MuiLink>
              <Trans>přejít na přihlášení</Trans>
            </MuiLink>
          </Link>
        )}
      </Wrapper>
    </MainLayout>
  );
};

export default ErrorPage;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;
